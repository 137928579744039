<template>
  <div>
    <div class="mobileScreen-main-container">
      <MobileHeader />

      <div class="mobileScreen-main-innerContainer">
        <div class="mobileScreen-TD-head m-3">
          Test Details
        </div>
        <hr />

        <div class="mobileScreen-TD-mainBody">
          <div>
            <div class="mobileScreen-TD-body-head">Test Name</div>
            <div
              class="mt-1 mobileScreen-TD-body-new-subhead"
              style="word-wrap: break-word;"
            >
              {{ testDetails.name }}
            </div>
          </div>

          <div class="mt-3">
            <div class="mobileScreen-TD-body-head">Test Description</div>
            <div
              class="mt-1 mobileScreen-TD-body-new-subhead"
              style="word-wrap: break-word;"
              v-html-safe="testDetails.config.testDesc"
            ></div>
          </div>

          <div class="mt-3" v-if="testDetails.config.testInst != ''">
            <div class="mobileScreen-TD-body-head">Test Instructions</div>
            <div
              class="mt-1 mobileScreen-TD-body-new-subhead"
              style="word-wrap: break-word;"
              v-html-safe="testDetails.config.testInst"
            ></div>
          </div>

          <div class="mt-3">
            <div class="mobileScreen-TD-body-head">Test Organizer</div>
            <div
              class="mt-1 mobileScreen-TD-body-new-subhead"
              style="word-wrap: break-word;"
            >
              {{ testDetails.config.testOrg }}
            </div>
          </div>

          <div class="mt-3">
            <div class="mobileScreen-TD-otherInfo mt-2">
              <div>
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/calendarstartDate.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Start Date
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ formatDateMob(testDetails.config.opentestdt) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/calendarstartDate.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">End Date</div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ formatDateMob(testDetails.config.closetestdt) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/hourglass-highduration.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">Duration</div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.config.testDuration }} Minutes
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/repeatattempts.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Total Attempts
                    </div>
                    <div
                      class="mobileScreen-TD-otherInfo-subhead mt-1 d-flex align-items-center"
                    >
                      <div>{{ testDetails.config.maxnoattempts }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="mt-2"
                v-if="
                  testDetails.config.timebnvalue != null &&
                    testDetails.config.maxnoattempts > 1
                "
              >
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/repeatattempts.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Time Between Attempts
                    </div>
                    <div
                      class="mobileScreen-TD-otherInfo-subhead mt-1 d-flex align-items-center"
                    >
                      {{ testDetails.config.timebnvalue }}
                      {{
                        testDetails.config.timebnsel == "mins"
                          ? "Minutes"
                          : testDetails.config.timebnsel == "hrs"
                          ? "Hours"
                          : "Days"
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/list-detailstotal-questions.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Total Questions
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.noOfQuestions }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/list-detailstotal-questions.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Total Marks
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.marks }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/list-detailstotal-questions.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Negative Marks
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.config.negativeMarkValue }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/calendarstartDate.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Total Sections
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.numberOfSections }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div class="mb-4">
                    <img
                      src="../../assets/mobileScreenIcons/chart-bar 1diff-level.svg"
                      alt=""
                    />
                  </div>
                  <div class="mt-1 ml-2">
                    <div class="mobileScreen-TD-otherInfo-head">
                      Difficulty Level
                    </div>
                    <div class="mobileScreen-TD-otherInfo-subhead mt-1">
                      {{ testDetails.difficultyLevel }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="testDetails.config.communicationTest"
              class="comm-tst-banner mt-3 d-flex"
            >
              <div class="mr-3">
                <img src="../../assets/mobile-newUI-imgs/comm-tst-mob.svg" />
              </div>
              <div>
                <div class="cmtst-banner-hd">Communication Test</div>
                <div class="cmtst-banner-txt mt-1">
                  Describe the picture in your own words using keywords given
                  below
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mobileScreen-TD-footer d-flex align-items-center justify-content-between bluebtns"
        >
          <b-button
            class="mobileScreen-leaveBtn mr-3"
            @click="$bvModal.show('mobileLeaveTest')"
            >Leave Test</b-button
          >
          <b-button class="mobileScreen-startBtn" @click="next()"
            >Continue</b-button
          >
        </div>
      </div>
    </div>
    <b-modal id="mobileLeaveTest" centered hide-footer hide-header class="p-0">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>

        <div class="mt-3 mobileScreen-modal-subhead text-center">
          Are you sure you want to leave the test ? <br />
          **Please note this action cannot be undone**
        </div>

        <div
          class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
        >
          <div class="w-50">
            <b-button
              class="mobileScreen-startBtn w-100"
              @click="$bvModal.hide('mobileLeaveTest')"
              >Cancel</b-button
            >
          </div>
          <div class="w-50">
            <b-button @click="leave()" class="mobileScreen-leaveBtn mt-3 w-100"
              >Leave Test</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MobileHeader from "./MobileHeader.vue";
import testDetails from "../../mixins/testDetails";

export default {
  name: "TestDetails",
  mixins: [testDetails],
  components: {
    MobileHeader,
  },
  data: () => ({
    property: "value",
  }),
};
</script>

<style scoped>
.recClass {
  margin-top: -245px;
}
.recClassModel {
  margin-top: -210px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  margin: auto;
}
.mtd-detail-container ::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}
.mtd-detail-container ::-webkit-scrollbar-thumb {
  background: #72738e;
  border-radius: 10px;
}
.mtd-detail-container ::-webkit-scrollbar {
  width: 5px;
}
</style>
